import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import ChartTipTimeBase from './ChartTipTimeBase'

function AnomalyWarningChartTip (props){
	const {payload} = props
	if(payload===null || !payload[0]) return false

	const value = payload[0].value
	return <ChartTipTimeBase {...props}>
		<Box center margin={7}>
			<Text>{value*100+'%'}</Text>
		</Box>
	</ChartTipTimeBase>
}

export default AnomalyWarningChartTip