import React from 'react'
import {useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import AnomalyChartSection from './AnomalyChartSection'
import AnalyticWarningChartTip from './AnalyticWarningChartTip'

function AnalyticWarningLevelChart ({data, expandedAnalytics, onChangeExpanded, allTimesArray, recentTimeArray}){
	const theme = useTheme()

	const awArray = data.m_RecentHSs.m_Values

	const plots = recentTimeArray.map(({time,x})=>{
		return {
			Hs: awArray[x]/100,
			time,
			x
		}
	})

	return <AnomalyChartSection 
		title="Compliance" 
		expandedAnomalies={expandedAnalytics} 
		onChangeExpanded={onChangeExpanded}
	>
		<ResponsiveContainer width={"100%"} height={150}>
			<LineChart 
				data={plots}
				syncId="recentSamples1"
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
			>
	  			<YAxis
	  				type="number"
	  				ticks={[0,.5,1]}
	  				tickFormatter={(v)=>v*100}
	  				dataKey="Hs"
	  				allowDecimals={false}
					tickLine={false}
	  				position="left"
	      			domain={[0, 1]}
	      			stroke={theme.palette.text.disabled}
	  			/>
	  			<XAxis
					type="number" 
					dataKey="x"
					position="bottom"
					tickCount={10}
					allowDecimals={false}
					tickLine={false}
					tickFormatter={(x)=>(allTimesArray[x]||{}).time}
					domain={["dataMin", "dataMax"]}
					stroke={theme.palette.text.disabled}
				/>
				<CartesianGrid 
					stroke={theme.palette.text.disabled}
				/>
				<Tooltip
					isAnimationActive={false}
					content={<AnalyticWarningChartTip allTimesArray={allTimesArray} />}
				/>
			    <Line 
			    	dot={false}
			    	type="step"
			    	fillOpacity={1}
			    	isAnimationActive={false}
			    	dataKey="Hs"  
			    	stroke={theme.palette.primary.main}
			    	strokeWidth={2}
			    />
			</LineChart>
		</ResponsiveContainer>
	</AnomalyChartSection>
}

export default AnalyticWarningLevelChart