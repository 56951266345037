import React from 'react'
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import AnomalyChartSection from './AnomalyChartSection'
import AnomalyDetectionsChartTip from './AnomalyDetectionsChartTip'

function AnalyticDetectionsChart ({data, onChangeExpanded, expandedAnalytics, allTimesArray, recentTimeArray}){
	const theme = useTheme()

	const adArray = data.m_RecentPIs.m_Values
	const plots = recentTimeArray.map(({time,x})=>{
		return {
			ad:adArray[x],
			time,
			x
		}
	})

	return <AnomalyChartSection title="Probability Index" expandedAnomalies={expandedAnalytics} onChangeExpanded={onChangeExpanded} >
		<ResponsiveContainer width={"100%"} height={250}>
			<LineChart 
				data={plots}
				syncId="recentSamples1"
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
			>
	  			<YAxis
	  				type="number"
	  				dataKey="ad"
	  				allowDecimals={false}
					tickLine={false}
	  				position="left"
					domain={[0, 1000]}
	      			stroke={theme.palette.text.disabled}
	  			/>
	  			<XAxis
					type="number" 
					dataKey="x"
					position="bottom"
					tickCount={10}
					allowDecimals={false}
					tickLine={false}
					tickFormatter={(x)=>(allTimesArray[x]||{}).time}
					domain={["dataMin", "dataMax"]}
					stroke={theme.palette.text.disabled}
				/>
				<CartesianGrid 
					stroke={theme.palette.text.disabled}
				/>
				<Tooltip isAnimationActive={false} content={<AnomalyDetectionsChartTip allTimesArray={allTimesArray} />} />
			    <Line 
			    	dot={false}
			    	type="step"
			    	isAnimationActive={false}
			    	dataKey="ad" 
			    	stroke={theme.palette.primary.main}
			    	strokeWidth={2} 
			    />
			</LineChart>
		</ResponsiveContainer>
	</AnomalyChartSection>
}

export default AnalyticDetectionsChart