import React from 'react'
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine, CartesianGrid, Tooltip } from 'recharts';
import AnomalyChartSection from './AnomalyChartSection'
import AnomalyIndexChartTip from './AnomalyIndexChartTip'

function AnalyticIndexesChart ({data, expandedAnalytics, allTimesArray, onChangeExpanded, recentTimeArray}){
	const theme = useTheme()

	const aiArray = data.m_RecentOMs.m_Values

	const plots = recentTimeArray.map(({time,x})=>{
		return {
			ai:aiArray[x],
			time,
			x
		}
	})

	return <AnomalyChartSection title="Operational Mode" expandedAnomalies={expandedAnalytics} onChangeExpanded={onChangeExpanded}>
		<ResponsiveContainer width={"100%"} height={250}>
			<LineChart 
				data={plots}
				syncId="recentSamples1"
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
			>
	  			<YAxis
	  				type="number"
					tickCount={10}
	  				dataKey="ai"
	  				allowDecimals={false}
					tickLine={false}
	  				position="left"
					domain={["dataMin", "dataMax"]}
	      			stroke={theme.palette.text.disabled}
	  			/>
	  			<XAxis
					type="number" 
					dataKey="x"
					position="bottom"
					tickCount={10}
					allowDecimals={false}
					tickLine={false}
					tickFormatter={(x)=>(allTimesArray[x]||{}).time}
					domain={["dataMin", "dataMax"]}
					stroke={theme.palette.text.disabled}
				/>
				<CartesianGrid 
					stroke={theme.palette.text.disabled}
				/>
				<Tooltip isAnimationActive={false} content={<AnomalyIndexChartTip allTimesArray={allTimesArray} />} />
			    <Line 
			    	dot={false}
			    	type="linear"
			    	isAnimationActive={false}
			    	dataKey="ai" 
			    	stroke={theme.palette.primary.main}
			    	strokeWidth={1} 
			    />
			</LineChart>
		</ResponsiveContainer>
	</AnomalyChartSection>
}

export default AnalyticIndexesChart